import React from 'react'
import { useStaticQuery, Link, graphql , withPrefix} from 'gatsby'

export default function Navbar({}) {
  const data = useStaticQuery(graphql`
    query {
    file(relativePath: {eq: "logos@2x.png"}) {
      publicURL
    }
  }
`)

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container" style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div className="header">
          <div className="logo navbar-brand" >
            <Link to="/" className="" title="logo">
              <img src={`${withPrefix('/')}logos@2x.png`}
                alt="logo pf business services"
                width="130px" height="130px"
              />
            </Link>
          </div>

          <div className="continfo">
            <span className="address">58 Mitropolews Street</span>
            <span className="address">10563 Athens, Greece</span>
            <span className="mail"><a href="mailto:info@ipf.gr">info@ipf.gr</a></span>
          </div>
        </div>
      </div>

    </nav>
  )
}

